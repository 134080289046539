export const patientMapper = (response) => {
  return {
    first_name: response?.patientName ?? "-",
    full_name: `${response?.patientName ?? "-"}`,
    last_name: response?.patientName ?? "-",
  };
};

export const doctorMapper = (response) => {
  return {
    first_name: response?.["doctor.name"] ?? "-",
    full_name: response?.["doctor.name"] ?? "-",
    last_name: response?.["doctor.name"] ?? "-",
  };
};
export const TSMapper = (ts) => {
  return {
    advanced_modules: {},
    front_title: ts?.title ?? "",
    id: ts?.uuid ?? "1",
    label: `${ts?.title ?? ""} ${ts?.version ?? ""}`,
    lower_overcorrection_steps: ts?.lowerOvercorrectionSteps,
    lower_steps: ts?.lowerSteps ?? 0,
    missing_teeth: ts?.missingTeeth,
    passive_aligners: ts?.flags?.hasPassiveAligners ?? false,
    revised_data: {},
    start_together: ts.sync === "Start together", // true or false
    state: ts?.status,
    technician_notes: ts?.manufacturingNotes,
    teeth_movements_extracted: [],
    upper_overcorrection_steps: ts?.upperOvercorrectionSteps,
    upper_steps: ts?.upperSteps ?? 0,
    value: ts?.uuid,
  };
};
